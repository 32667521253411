import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router";
import { createGtm } from "@gtm-support/vue-gtm";

createApp(App)
  .use(Router)
  .use(
    createGtm({
      id: "GTM-MG72FLP",
      debug: false,
      vueRouter: Router,
    }),
  )
  .mount("#app");
