import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { title: "Fiews.io - Blockchain connectivity as a Service" },
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "home" */ "./components/pages/Home.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    meta: { title: "Pricing - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "pricing" */ "./components/pages/Pricing.vue"
      ),
  },
  {
    path: "/solutions",
    name: "solutions",
    meta: { title: "Solutions - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "solutions" */ "./components/pages/Solutions.vue"
      ),
  },
  {
    path: "/blockchains/ethereum",
    name: "ethereum",
    meta: { title: "Ethereum RPC Connectivity - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "ethereum" */ "./components/pages/Ethereum.vue"
      ),
  },
  {
    path: "/blockchains/binance-smart-chain",
    name: "binance-smart-chain",
    meta: { title: "Binance Smart Chain RPC Connectivity - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "bsc" */ "./components/pages/BinanceSmartChain.vue"
      ),
  },
  {
    path: "/blockchains/polygon",
    name: "polygon",
    meta: { title: "Polygon RPC Connectivity - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "polygon" */ "./components/pages/Polygon.vue"
      ),
  },
  {
    path: "/blockchains/avalanche-c-chain",
    name: "avalanche-c-chain",
    meta: { title: "Avalanche C-Chain RPC Connectivity - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "avalanche" */ "./components/pages/Avalanche.vue"
      ),
  },
  {
    path: "/about",
    name: "about",
    meta: { title: "About - Fiews.io" },
    component: () =>
      import(/* webpackChunkName: "about" */ "./components/pages/About.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    meta: { title: "Terms of Use - Fiews.io" },
    component: () =>
      import(/* webpackChunkName: "terms" */ "./components/pages/Terms.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    meta: { title: "Privacy Policy - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "./components/pages/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/refunds",
    name: "refunds",
    meta: { title: "Refund and Cancellation Policy - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "refunds" */ "./components/pages/Refunds.vue"
      ),
  },
  {
    path: "/contact",
    name: "contact",
    meta: { title: "Contact Us - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "./components/pages/Contact.vue"
      ),
  },
  {
    path: "/dedicated-nodes",
    name: "dedicated-nodes",
    meta: { title: "Dedicated Blockchain Nodes for Enterprise - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "dedicated-nodes" */ "./components/pages/DedicatedNodes.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    meta: { title: "Page not found - Fiews.io" },
    component: () =>
      import(
        /* webpackChunkName: "notfound" */ "./components/pages/NotFound.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
      };
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string;
  next();
});

export default router;
