<template>
  <span class="typing" ref="typing"></span>
</template>

<script>
import Typed from "typed.js";

export default {
  name: "Typing",
  props: {
    strings: Array,
    typeSpeed: {
      type: Number,
      default: 40,
    },
    backSpeed: {
      type: Number,
      default: 40,
    },
    backDelay: {
      type: Number,
      default: 1000,
    },
    loop: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    new Typed(this.$refs.typing, {
      strings: this.strings,
      typeSpeed: this.typeSpeed,
      backSpeed: this.backSpeed,
      backDelay: this.backDelay,
      loop: this.loop,
    });
  },
};
</script>

<style scoped></style>
