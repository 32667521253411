<template>
  <!-- WELCOME -->
  <section class="position-relative py-8 py-md-11 mb-9">
    <!-- Shape -->
    <div class="shape shape-fluid-x shape-blur-1 text-gray-200">
      <svg viewBox="0 0 723 569" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M703.969 241.602l-.006-.003C716.081 262.97 723 287.677 723 314c0 68.917-47.425 126.757-111.42 142.665L246.7 556.937C226.465 564.729 204.481 569 181.5 569 81.26 569 0 487.74 0 387.5c0-34.256 9.49-66.296 25.985-93.633l-.016.008L141.512 77.548C162.753 33.305 207.123 2.273 258.951.12l.008-.12h251.04l.003.01c41.848.557 78.081 24.378 96.356 59.12l.001-.005 97.61 182.477z"
          fill="currentColor"
        />
      </svg>
    </div>

    <!-- Contetn -->
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 order-md-2">
          <!-- Image -->
          <div class="img-skewed img-skewed-start mb-8 mb-md-0">
            <!-- Code -->
            <div
              class="card rounded-lg bg-black mb-6 mb-md-0 img-skewed-item screenshot"
            >
              <div class="card-header border-white-10">
                <!-- Controls -->
                <div class="d-flex">
                  <span
                    class="bg-danger rounded-circle"
                    style="width: 10px; height: 10px"
                  ></span>
                  <span
                    class="bg-warning rounded-circle ms-1"
                    style="width: 10px; height: 10px"
                  ></span>
                  <span
                    class="bg-success rounded-circle ms-1"
                    style="width: 10px; height: 10px"
                  ></span>
                </div>
              </div>
              <div class="card-body" style="min-height: 307px">
                <!-- Code -->
                <code class="highlight">
                  $&nbsp;<Typing
                    :strings="this.dataStrings"
                    :type-speed="20"
                    :back-speed="2"
                    :back-delay="3000"
                  ></Typing>
                </code>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 order-md-1" data-aos="fade-up">
          <!-- Heading -->
          <h1 class="display-3">
            <Typing
              :strings="[
                'Ethereum',
                'Binance Smart Chain',
                'Polygon',
                'Avalanche C-Chain',
              ]"
              :type-speed="100"
              :back-speed="100"
              :back-delay="3000"
            ></Typing>
            <br />
            connectivity for your <br />
            <span class="text-primary"
              ><Typing
                :strings="['node', 'app', 'website', 'project']"
              ></Typing> </span
            >.
          </h1>

          <!-- Text -->
          <p class="lead text-muted mb-6 mb-md-8">
            Fiews provides connectivity to the blockchain, so you can focus on
            your product instead of running a blockchain node.
          </p>

          <!-- Buttons -->
          <a
            href="https://dashboard.fiews.io/register"
            class="btn btn-primary me-1 lift"
          >
            Get started now <i class="fe fe-arrow-right ms-3"></i>
          </a>
          <router-link to="/solutions" class="btn btn-primary-soft lift"
            >Learn more
          </router-link>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- TESTIMONIALS -->
  <section class="py-8">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-12 col-md">
          <!-- Blockquote -->
          <blockquote class="blockquote mb-8 mb-md-0">
            <!-- Logo -->
            <div class="img-fluid mb-5 mb-md-6">
              <img
                src="../../assets/img/logos/sdl-text-black.png"
                alt="Secure Data Links logo"
                style="max-height: 60px"
              />
            </div>

            <!-- Text -->
            <p class="lead text-gray-700 mb-5 mb-md-7">
              "By using Fiews we are able to launch Chainlink nodes on new
              blockchains much quicker. This has allowed us to stay focused on
              operating our Chainlink node while Fiews takes care of the
              blockchain connectivity."
            </p>

            <!-- Footer -->
            <p class="fs-sm fw-bold mb-0">Seamus McNab</p>
            <p class="fs-sm text-muted mb-0">Secure Data Links</p>
          </blockquote>
        </div>
        <div class="col-1"></div>
        <div class="col-1 border-start"></div>
        <div class="col-12 col-md">
          <!-- Blockquote -->
          <blockquote class="blockquote mb-0">
            <!-- Logo -->
            <div class="img-fluid mb-5 mb-md-6" style="max-width: 120px">
              <img
                src="../../assets/img/logos/yaxis.svg"
                alt="yAxis logo"
                style="max-height: 60px"
              />
            </div>

            <!-- Text -->
            <p class="lead text-gray-700 mb-5 mb-md-7">
              "Fiews was able to handle extreme amounts of traffic from our
              application, which is crucial to our users in a phase where we
              have gone through fast growth."
            </p>

            <!-- Footer -->
            <p class="fs-sm fw-bold mb-0">transferAndCall</p>
            <p class="fs-sm text-muted mb-0">yAxis.io</p>
          </blockquote>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- PRICING -->
  <section class="pt-8 mb-n5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="50">
          <!-- Card -->
          <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
            <div class="card-body">
              <!-- Badge -->
              <span
                class="badge rounded-pill bg-primary-desat badge-float badge-float-outside"
              >
                From $10/mo
              </span>

              <!-- Heading -->
              <h4 class="fw-bold">Chainlink node</h4>

              <!-- Text -->
              <p class="text-muted">
                Bring your Chainlink node to life with Ethereum WS connectivity.
              </p>

              <!-- Link -->
              <a
                href="https://dashboard.fiews.io/register"
                class="fs-sm fw-bold text-decoration-none"
              >
                Get started now <i class="fe fe-arrow-right ms-3"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="100">
          <!-- Card -->
          <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
            <div class="card-body">
              <!-- Badge -->
              <span
                class="badge rounded-pill bg-primary-desat badge-float badge-float-outside"
              >
                From $10/mo
              </span>

              <!-- Heading -->
              <h4 class="fw-bold">General purpose RPC</h4>

              <!-- Text -->
              <p class="text-muted">
                Connect your entire project to the Ethereum network.
              </p>

              <!-- Link -->
              <a
                href="https://dashboard.fiews.io/register"
                class="fs-sm fw-bold text-decoration-none"
              >
                Get started now <i class="fe fe-arrow-right ms-3"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="150">
          <!-- Card -->
          <div class="card shadow-light-lg lift lift-lg">
            <div class="card-body">
              <!-- Badge -->
              <span
                class="badge rounded-pill bg-primary-desat badge-float badge-float-outside"
              >
                $100/mo addon
              </span>

              <!-- Heading -->
              <h4 class="fw-bold">Addon network access</h4>

              <!-- Text -->
              <p class="text-muted">
                Include access to networks like BSC and Polygon.
              </p>

              <!-- Link -->
              <a
                href="https://dashboard.fiews.io/register"
                class="fs-sm fw-bold text-decoration-none"
              >
                Get started now <i class="fe fe-arrow-right ms-3"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <!-- CTA -->
  <section class="py-10 py-md-13 bg-gray-200 bg-between">
    <!--style="
      background-image: url(/img/illustrations/illustration-1-cropped.png), url(/img/illustrations/illustration-3-cropped.png);
    "-->
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 text-center">
          <!-- Heading -->
          <h2 class="fw-bold">Get blockchain access now</h2>

          <!-- Text -->
          <p class="fs-lg text-muted mb-8 px-lg-9">
            Getting started is quick and easy. Get access to Ethereum Mainnet
            within minutes, or upgrade your subscription to include Binance
            Smart Chain.
          </p>

          <!-- Button -->
          <a
            href="https://dashboard.fiews.io/register"
            class="btn btn-primary-desat lift"
          >
            Get started now
          </a>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>
</template>

<script>
import Typing from "../common/Typing.vue";
export default {
  name: "Home",
  components: { Typing },
  data: function () {
    return {
      dataStrings: [
        [
          `curl&nbsp;-X&nbsp;POST&nbsp;\\`,
          `&nbsp;&nbsp;-d&nbsp;'{"id":1,"method":"eth_blockNumber"}' \\`,
          `&nbsp;&nbsp;https://mainnet.fiews.io/v1/myKey`,
          '<span class="text-success">{',
          '&nbsp;&nbsp;"jsonrpc":&nbsp;"2.0",',
          '&nbsp;&nbsp;"id":&nbsp;1,',
          '&nbsp;&nbsp;"result":&nbsp;"0xbeb2d3"',
          "}</span>",
        ].join("<br />"),
        [
          `wscat&nbsp;-c&nbsp;wss://mainnet.fiews.io/v1/myKey`,
          `&gt;&nbsp;{"id":1,"method":"eth_blockNumber"}`,
          '&lt;&nbsp;<span class="text-success">{',
          '&nbsp;&nbsp;&nbsp;&nbsp;"jsonrpc":&nbsp;"2.0",',
          '&nbsp;&nbsp;&nbsp;&nbsp;"id":&nbsp;1,',
          '&nbsp;&nbsp;&nbsp;&nbsp;"result":&nbsp;"0xbeb2d3"',
          "&nbsp;&nbsp;}</span>",
        ].join("<br />"),
      ],
    };
  },
};
</script>

<style scoped></style>
