
import { Options, Vue } from "vue-class-component";
import Home from "./components/pages/Home.vue";
import Navbar from "./components/Navbar.vue";
import "./assets/js/theme";

@Options({
  components: {
    Home,
    Navbar,
  },
})
export default class App extends Vue {}
