<template>
  <!-- NAVBAR -->
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <!-- Brand -->
      <router-link class="navbar-brand" to="/">
        <img
          src="../assets/img/logos/fiews.png"
          class="navbar-brand-img"
          alt="..."
          style="max-width: 160px"
        />
      </router-link>

      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <!-- Toggler -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fe fe-x"></i>
        </button>

        <!-- Navigation -->
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" id="navbarSolutions" to="/solutions">
              Solutions
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="navbarBlockchains"
              data-bs-toggle="dropdown"
              href="#"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Blockchains
            </a>
            <div
              class="dropdown-menu dropdown-menu-md"
              aria-labelledby="navbarBlockchains"
            >
              <div class="list-group list-group-flush">
                <router-link class="list-group-item" to="/blockchains/ethereum">
                  <!-- Icon -->
                  <div class="icon icon-sm text-primary">
                    <img
                      src="../assets/img/logos/ethereum.svg"
                      width="24"
                      height="24"
                      alt="Ethereum"
                    />
                  </div>

                  <!-- Content -->
                  <div class="ms-4">
                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-primary mb-0">
                      Ethereum
                    </h6>
                  </div>
                </router-link>
                <router-link
                  class="list-group-item"
                  to="/blockchains/binance-smart-chain"
                >
                  <!-- Icon -->
                  <div class="icon icon-sm text-primary">
                    <img
                      src="../assets/img/logos/binance.svg"
                      width="24"
                      height="24"
                      alt="Binance"
                    />
                  </div>

                  <!-- Content -->
                  <div class="ms-4">
                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-primary mb-0">
                      Binance Smart Chain
                    </h6>
                  </div>
                </router-link>
                <router-link class="list-group-item" to="/blockchains/polygon">
                  <!-- Icon -->
                  <div class="icon icon-sm text-primary">
                    <img
                      src="../assets/img/logos/matic-token-icon.svg"
                      width="24"
                      height="24"
                      alt="Polygon"
                    />
                  </div>

                  <!-- Content -->
                  <div class="ms-4">
                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-primary mb-0">
                      Polygon
                    </h6>
                  </div>
                </router-link>
                <router-link class="list-group-item" to="/blockchains/avalanche-c-chain">
                  <!-- Icon -->
                  <div class="icon icon-sm text-primary">
                    <img
                      src="../assets/img/logos/avalanche.svg"
                      width="24"
                      height="24"
                      alt="Avalanche"
                    />
                  </div>

                  <!-- Content -->
                  <div class="ms-4">
                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-primary mb-0">
                      Avalanche C-Chain
                    </h6>
                  </div>
                </router-link>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" id="navbarPricing" to="/pricing">
              Pricing
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" id="navbarPricing" to="/dedicated-nodes">
              Enterprise
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" id="navbarAbout" to="/about">
              About
            </router-link>
          </li>
        </ul>

        <!-- Button -->
        <a
          class="navbar-btn btn btn-sm lift ms-auto"
          href="https://dashboard.fiews.io"
          target="_blank"
        >
          Log in
        </a>
        <a
          class="navbar-btn btn btn-sm btn-primary lift ms-4"
          href="https://dashboard.fiews.io/register"
        >
          Sign up
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  mounted() {
    import("../assets/js/navbar-dropdown.js");
    import("../assets/js/navbar.js");
  },
};
</script>

<style scoped></style>
